<template>
  <div>
    <div class="cardModel" v-if="this.isCard">
      <div style="padding: 50px;">
        <el-row :gutter="20">
          <el-col :span="6" v-for="(item, index) in doingSiteList" :key="index">
            <el-card shadow="hover" :body-style="{ padding: '5px', height: '9.375rem' }">
              <div style="padding: 5px">工地名称:&nbsp;{{item.siteName}}</div>
              <div style="padding: 5px">工地地址:&nbsp;{{item.siteAddress}}</div>
              <div style="padding: 5px">预估方量:&nbsp;{{item.quantityNumber}}</div>
              <el-button style="padding: 5px" type="text" class="button" @click="gotoDetail(item)">查看详情</el-button>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="detailcontent" v-if="!this.isCard">
      <div @click="goBack" style="cursor:pointer">&lt;返回</div>
      <div style="font-size:30px;text-align:center;margin: 10px 10px;">{{current.siteName}}</div>
      <div style="font-size:20px;text-align:center;margin-bottom: 10px;">工地地址:&nbsp;{{current.siteAddress}}</div>
      <div style="margin-top: 20px;" v-for="(item, index) in dataList" :key="index">
        <div style="margin-left: 10px;">施工部位:&nbsp;{{item.constructionPart}}</div>
        <div class="card">
          <div class="card-panel">
            <div class="card-panel-text">
              任务方量
            </div>
            <!-- <count-to :start-val="0" :end-val="item.quantityNumber" :duration="2000" /> -->
            <div class="element" style="border-color: #9400D3">{{item.quantityNumber}}</div>
          </div>
          <div class="card-panel">
            <div class="card-panel-text">
              已完成方量
            </div>
            <!-- <countTo :startVal='1' :endVal='item.currentQuantityNumber' :duration='2000'></countTo> -->
            <div class="element" style="border-color: #FF69B4">{{item.currentQuantityNumber || 0}}</div>
          </div>
          <div class="card-panel" v-for="(v, carIndex) in item.carNumberList" :key="carIndex">
            <div class="card-panel-text">
              {{v}}
            </div>
            <!-- <countTo :startVal='1' :endVal='item.carTotalQuantityList[v]' :duration='2000'></countTo> -->
            <div class="element" :class="'border-color' + [carIndex % 6]">{{item.carTotalQuantityList[v]}}</div>
          </div>
        </div>
        <div style="margin-left: 10px;">车辆小时方量列表</div>
        <div style="margin-left: 5px;">
          <el-table :row-style="tableRowStyle"
                    :header-row-style="tableHeaderColor" size="mini" :data="item.carDataList" border style="max-height: 400px;">
            <el-table-column prop="reportTime" label="时间" width="180" fixed="left"></el-table-column>
            <el-table-column  width="120"
                              v-for="(item, index) in item.carNumberList"
                              :key="index"
                              :prop="item"
                              :label="item">
              <template slot-scope="scope">
                <span> {{ scope.row[scope.column.property] }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'dispatch-quantity',
  data () {
    return {
      isCard: true,
      doingSiteList: [],
      dataList: [],
      current: {},
      timer: null
    }
  },
  created () {
    this.queryCardData()
  },
  destroyed () {
    clearTimeout(this.timer)
  },
  watch: {
    dataList: {
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 设置表格行的样式
    tableRowStyle ({ row, rowIndex }) {
      let obj = { 'background-color': '#02208c', 'opacity': '0.7', 'color': 'white' }
      return obj
    },
    // 设置表头行的样式
    tableHeaderColor ({ row, column, rowIndex, columnIndex }) {
      let obj = { 'background-color': '#02208c', 'opacity': '0.7', 'color': '#02208c' }
      return obj
    },
    /** 获取初始数据 */
    queryCardData () {
      this.doingSiteList = []
      this.dataList = []
      this.current = {}
      this.$http({
        url: this.$http.adornUrl('/dispatch/querycarddata'),
        method: 'post',
        data: {}
      }).then((data) => {
        if (data && data.code === 200) {
          this.doingSiteList = data.datas
        } else {
          console.log(data)
        }
      })
    },
    /** 获取角色列表 */
    gotoDetail (item) {
      this.isCard = false
      this.current = item
      this.queryDetail()
    },
    queryDetail () {
      clearTimeout(this.timer)
      let tempList = []
      let dispatchIds = this.current.dispatchIdList
      for (let index in dispatchIds) {
        let dispatchId = dispatchIds[index]
        this.$http({
          url: this.$http.adornUrl('/dispatch/querydetailddata/' + dispatchId),
          method: 'post',
          data: {}
        }).then(data => {
          if (data.code === 200) {
            tempList.push(data.datas)
          }
        }).catch(err => {
          console.log(err)
        })
      }
      this.dataList = tempList
      console.log(this.dataList)
      this.timer = setTimeout(this.queryDetail, 60 * 1000)
    },
    goBack () {
      this.isCard = true
      this.queryCardData()
      clearTimeout(this.timer)
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
    display: flex;
}
.card-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin: 5px;
    font-size: 20px;
    background-color: rgba(#02208c, 0.8);
    width: 200px;
    height: 200px;
}
.card-panel-text {
    margin-bottom: 10px;
}

.detailcontent {
    background-color: rgba(#02208c, 0.8);
    height: 100vh;
    color: white;
}

.el-table, .el-table-column {
    background-color: rgba(#02208c, .7);
}

.element{
           width: 150px;
           height: 150px;
           border-radius: 50%;
           border-width: 20px;
           border-style:  solid;
           display: flex;
           justify-content: center;
           align-items: center;
       }

.border-color0 {border-color: #7B68EE }
.border-color1 {border-color: #00CC33}
.border-color2 {border-color: #FF00FF }
.border-color3 {border-color: #FFA07A}
.border-color4 {border-color: #32CD32 }
.border-color5 {border-color: #FF0000}

.el-table, .el-table--border, .el-table--group {
    border: none !important
}
</style>
